.react-fancybox .box {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.react-fancybox .thumbnail img {
  max-width: 600px;
}

.react-fancybox .box .image-box {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  position: relative;
}

.react-fancybox .box .image-box .caption {
  text-align: center;
  font-size: 14px;
  margin-top: 7px;
}

.react-fancybox .fade-enter {
  animation: fadein 600ms;
}

.react-fancybox .fade-leave {
  animation: fadeout 600ms;
}

.react-fancybox .close-btn {
  background-color: white;
  width: 25px;
  border-radius: 50%;
  height: 25px;
  position: absolute;
  right: -13px;
  top: -13px;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

